import { React, useEffect, useState } from "react"
import tw from "twin.macro"
import { throttle } from "lodash"

const ScrollTop = () => {
  const hiddenStylesButton = tw`translate-y-1/3 opacity-0`
  const dispalyStylesButton = tw`translate-y-0 opacity-100`
  const isBrowser = typeof window !== "undefined"
  const offset = 400
  const [display, setDisplay] = useState(true)
  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const updateScrollState = () => {
      if (isBrowser && window.pageYOffset < offset && !display) {
        setDisplay(true)
      } else if (isBrowser && window.pageYOffset > offset && display) {
        setDisplay(false)
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", throttle(updateScrollState, 60))
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", updateScrollState)
      }
    }
  }, [display, isBrowser, offset])

  return (
    <div tw="pointer-events-none sticky bottom-4 right-0 flex w-full items-end px-4 md:bottom-9 md:px-10 lg:hidden">
      <button
        tw="pointer-events-auto ml-auto block transform bg-lilac-500 px-5 py-2 text-lg font-bold uppercase opacity-0 transition-all hover:bg-lilac-300 motion-reduce:transition-none md:text-xl"
        css={[display && hiddenStylesButton, !display && dispalyStylesButton]}
        onClick={scrollTop}
      >
        Nach oben
      </button>
    </div>
  )
}

export default ScrollTop
