import React from "react"
// import PropTypes from 'prop-types'
import instagram from "../../images/svgs/instagram.svg"
import "twin.macro"
import Icon from "../icon"

const Instagram = props => {
  return (
    <a
      href="https://www.instagram.com/bs.gleichstellung/"
      target="_blank"
      rel="noreferrer noopener"
      tw="flex h-11 w-11 items-center rounded-full p-2 pt-3 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
      aria-label="Instagram"
    >
      <Icon svg={instagram} tw="h-7 w-7 fill-current" />
    </a>
  )
}

Instagram.propTypes = {}

export default Instagram
