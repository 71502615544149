import React from "react"
import tw from "twin.macro"
// import { debounce } from '../../utilities/helpers';

const Bar = ({ children, collapsed }) => {
  const collapsedStyles = tw`h-18 shadow-[0 1px 0px 0px rgba(0,0,0,1)] bg-white` //lg:h-32 xl:h-22
  const normalStyles = tw`h-28 lg:h-52 xl:h-48`

  return (
    <header
      className="global-header"
      role="banner"
      tw="fixed z-30 flex w-full flex-shrink-0 items-center transition-[height] transition-[background-color] transition-[border] duration-500 ease-in-out"
      css={[collapsed && collapsedStyles, !collapsed && normalStyles]}
    >
      {children}
    </header>
  )
}

export default Bar
