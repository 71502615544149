import React, { createContext, useState } from "react"

export const TabContext = createContext()

export const TabProvider = ({ children }) => {
  const [showFirstDay, setShowFirstDay] = useState(false)
  return (
    <TabContext.Provider value={{ showFirstDay, setShowFirstDay }}>
      {children}
    </TabContext.Provider>
  )
}
