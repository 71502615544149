import React from "react"
// import PropTypes from 'prop-types'
import linkedin from "../../images/svgs/linkedin.svg"
import "twin.macro"
import Icon from "../icon"

const Linkedin = props => {
  return (
    <a
      href="https://www.linkedin.com/company/bundesstiftung-gleichstellung"
      target="_blank"
      rel="noreferrer noopener"
      tw="flex h-11 w-11 items-center rounded-full p-2 text-anthracite-500 hover:text-lilac-500 focus:text-lilac-500 motion-safe:transition-colors motion-safe:duration-300"
      aria-label="Linkedin"
    >
      <Icon svg={linkedin} title="Linkedin" tw="h-9 w-8 fill-current" />
    </a>
  )
}

Linkedin.propTypes = {}

export default Linkedin
